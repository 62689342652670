<template>
  <BaseCard
    title="datos del contacto"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <ContactForm
      ref="contact-form"
      v-model="contact"
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ContactForm from '@/components/contacts/form/ContactForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, ContactForm },
  data() {
    return {
      contact: null,
      loading: false,
    }
  },
  mounted() {
    this.loadContact()
  },
  methods: {
    async loadContact() {
      try {
        this.loading = true
        const response = await ApiRestService.get(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        this.contact = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.$refs['contact-form'].validateForm()
      if (!valid) {
        return
      }

      try {
        this.loading = true
        await ApiRestService.edit(this.$route.params.id, this.contact, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
